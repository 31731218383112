import React, { useState } from 'react';
import { LatestDomainScansFieldMap } from './websites-table';
import { IconButton, TableCell, TableRow } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { DomainDetailsRow } from './domain-details-row';
import { FilterField } from '@equally-ai-front/common/src/components/filters/types';

export interface DomainRowProps<R, T> {
  latestDomainScansResultMap: LatestDomainScansFieldMap[];
  latestDomainScansData: R;
  latestDomainScansDetailsMap: LatestDomainScansFieldMap[];
  domainDetailsTitle: string;
  domainDetailFields: Record<string, FilterField>;
  domainDetails: T[];
  emptyDomainDetailsText: string;
}

export const DomainRow = <R, T>(props: DomainRowProps<R, T>) => {
  const {
    latestDomainScansResultMap,
    latestDomainScansData,
    latestDomainScansDetailsMap,
    domainDetailsTitle,
    domainDetailFields,
    domainDetails,
    emptyDomainDetailsText,
  } = props;
  const [isDomainDetailsOpen, setIsDomainDetailsOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setIsDomainDetailsOpen(!isDomainDetailsOpen)}
          >
            {isDomainDetailsOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        {latestDomainScansResultMap.map((item, index) => (
          <TableCell key={`domains-table-cell-${index}`} align={item.align}>
            {item.format(latestDomainScansData, item.key)}
          </TableCell>
        ))}
      </TableRow>
      <DomainDetailsRow<T>
        isDomainDetailsOpen={isDomainDetailsOpen}
        latestDomainScansDetailsMap={latestDomainScansDetailsMap}
        domainDetailsTitle={domainDetailsTitle}
        domainDetailFields={domainDetailFields}
        domainDetails={domainDetails}
        emptyDomainDetailsText={emptyDomainDetailsText}
      />
    </>
  );
};
