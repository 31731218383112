import React from 'react';
import styled from 'styled-components';
import RocketLaunch from '../../assets/rocket-circle.svg';
import { Box, Typography } from '@mui/material';
import { PrimaryButton } from '../styled-components';

interface StartFreeTrialProps {
  onStartFreeTrialClick: () => void;
  isUserPanel?: boolean;
}
export const StartFreeTrial = (props: StartFreeTrialProps) => {
  const { onStartFreeTrialClick, isUserPanel } = props;

  return (
    <Wrapper $isuserpanel={isUserPanel}>
      <InfoSection>
        <IconWrapper>
          <Icon src={RocketLaunch} alt="rocket launch image icon" />
        </IconWrapper>
        <InfoText variant="body1">
          Your road to accessibility begins now!
        </InfoText>
      </InfoSection>
      <ButtonWrapper>
        <PrimaryButton
          color="primary"
          variant="contained"
          onClick={onStartFreeTrialClick}
        >
          Start 7-day free trial
        </PrimaryButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $isuserpanel?: boolean }>`
  background-color: #f2f2f2;
  width: 100%;
  max-width: ${(props) =>
    props.$isuserpanel ? 'calc(100% - 300px)' : '870px'};
  height: 80px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  position: fixed;
  bottom: 50px;
  left: 285px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 4;

  ${(props) =>
    !props.$isuserpanel &&
    `
      transform: translateX(-50%);
      left: 50%;
    `};

  ${(props) =>
    props.$isuserpanel &&
    `
      @media screen and (max-width: 1050px) {
        flex-direction: column;
        height: 150px;
        padding: 20px 30px;
      }
    `};

  @media screen and (max-width: 800px) {
    max-width: 95%;
    ${(props) => props.$isuserpanel && `left: 2.5%;`};
  }

  @media screen and (max-width: 650px) {
    height: 111px;
    padding: 15px 30px;
    ${(props) => !props.$isuserpanel && `flex-direction: column;`};
  }

  @media screen and (max-width: 380px) {
    height: 120px;
  }
`;

const IconWrapper = styled.div`
  width: fit-content;
  margin-right: 12px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 845px) {
    display: none;
  }
`;

const Icon = styled.img``;

const InfoSection = styled(Box)`
  display: flex;
  align-items: center;
`;

const InfoText = styled(Typography)`
  width: 384px;
  height: 33px;
  font-size: 22px;
  font-weight: 400;
  line-height: 33px;
  text-align: left;

  @media screen and (max-width: 845px) {
    text-align: center;
  }

  @media screen and (max-width: 650px) {
    font-size: 15px;
    line-height: 22.5px;
    width: fit-content;
  }
`;

const ButtonWrapper = styled(Box)``;
