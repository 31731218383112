import React from 'react';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

export const AccessibilityScoreIcon = ({
  score,
  previousScore,
}: {
  score: number;
  previousScore: number;
}) => {
  const getColor = () => {
    if (score < 80) return '#dc2626'; // red
    if (score >= 80 && score < 90) return '#f97316'; // orange
    return '#22c55e'; // green
  };

  return (
    <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      <span
        style={{
          display: 'inline-block',
          width: '12px',
          height: '12px',
          borderRadius: '50%',
          backgroundColor: getColor(),
        }}
      />
      <span>{Math.floor(score)}%</span>
      {score === previousScore ? null : score > previousScore ? (
        <ArrowUpward color="success" />
      ) : (
        <ArrowDownward color="error" />
      )}
    </span>
  );
};
