import { useAddNewFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-add-new-filter';
import { useFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-filter';
import React from 'react';
import styled from 'styled-components';
import { Pagination } from '@equally-ai-front/common/src/components/pagination';
import { usePagination } from '@equally-ai-front/common/src/hooks/use-pagination';
import {
  businessesWithDomainsFields,
  businessesWithDomainsMap,
  BusinessWithDomains,
  businessWithDomainsDetails,
  domainFields,
} from './consts';
import { DomainsPageTableHeader } from './domains-page-table-header';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { DomainRow } from '../../components/latest-scans-table/domain-row';

interface BusinessDomainsTableProps {
  currentTab: number;
  onTabChange: (evt: React.SyntheticEvent, newValue: number) => void;
  businessesWithDomains: BusinessWithDomains[];
}

const BUSINESSES_WITH_DOMAIN_TABLE_PAGE_LIMIT = 50;

export const BusinessDomainsTable = (props: BusinessDomainsTableProps) => {
  const { currentTab, onTabChange, businessesWithDomains } = props;

  const {
    visibleData,
    allFilterOptions,
    filteredOptions,
    searchTerm,
    filters,
    removeFilterType,
    setSearchTerm,
    handleSearchInputChange,
    addFilter,
  } = useFilter({
    filterOptions: businessesWithDomainsFields,
    data: businessesWithDomains,
  });

  const {
    handleOptionClick,
    handleFilterValueChange,
    handleFilterSecondValueChange,
    newFilter,
    isFilterFulfilled,
    handleFilterOperatorChange,
    filterValueOptions,
  } = useAddNewFilter({ allFilterOptions, visibleData });

  const {
    pageCount,
    currentData: visibleBusinessesWithDomains,
    handlePageChange,
    handleSort,
    sortOptions,
    searchTerm: domainSearchValue,
    handleSearch,
  } = usePagination(
    visibleData,
    BUSINESSES_WITH_DOMAIN_TABLE_PAGE_LIMIT,
    'businessName',
  );

  return (
    <Wrapper>
      <DomainsPageTableHeader
        currentTab={currentTab}
        onTabChange={onTabChange}
        filteredOptions={filteredOptions}
        searchTerm={searchTerm}
        filters={filters}
        removeFilterType={removeFilterType}
        setSearchTerm={setSearchTerm}
        handleSearchInputChange={handleSearchInputChange}
        addFilter={addFilter}
        handleOptionClick={handleOptionClick}
        handleFilterValueChange={handleFilterValueChange}
        handleFilterSecondValueChange={handleFilterSecondValueChange}
        newFilter={newFilter}
        isFilterFulfilled={isFilterFulfilled}
        handleFilterOperatorChange={handleFilterOperatorChange}
        filterValueOptions={filterValueOptions}
        domainSearchValue={domainSearchValue}
        handleSearch={handleSearch}
      />
      <TableContainer component={Paper}>
        <Table aria-label="business domains table">
          <TableHead>
            <TableRow>
              <TableCell />
              {Object.entries(businessesWithDomainsFields).map(
                ([_, businessWithDomainField]) => {
                  const currentSortKey = Object.keys(sortOptions)[0];
                  const currentSortOrder =
                    Object.values(sortOptions)[0] || 'desc';

                  return (
                    <TableCell
                      key={businessWithDomainField.key}
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      <TableSortLabel
                        active={currentSortKey === businessWithDomainField.key}
                        direction={currentSortOrder}
                        onClick={() => handleSort(businessWithDomainField.key)}
                      >
                        {businessWithDomainField.displayName}
                      </TableSortLabel>
                    </TableCell>
                  );
                },
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleBusinessesWithDomains.map(
              (businessWithDomain: BusinessWithDomains, bdidx) => {
                return (
                  <DomainRow
                    key={`${businessWithDomain.id}-${bdidx}`}
                    latestDomainScansResultMap={businessesWithDomainsMap}
                    latestDomainScansData={businessWithDomain}
                    latestDomainScansDetailsMap={businessWithDomainsDetails}
                    domainDetailsTitle="Business Domains"
                    domainDetailFields={domainFields}
                    domainDetails={businessWithDomain.domains}
                    emptyDomainDetailsText="No domains available for this business"
                  />
                );
              },
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination handlePageChange={handlePageChange} pageCount={pageCount} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
