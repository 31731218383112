import React from 'react';
import styled from 'styled-components';

interface ScanReportLayoutProps {
  children: any[];
  emptyContent?: React.ReactNode;
  isAuditPage?: boolean;
}

export const ScanReportLayout = ({
  children,
  emptyContent,
  isAuditPage,
}: ScanReportLayoutProps) => {
  return (
    <Wrapper $isauditpage={isAuditPage}>
      {children.length > 0
        ? children.map((child, idx) => (
            <div key={`scan-report-element-${idx}`}>{child}</div>
          ))
        : emptyContent || 'No content'}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $isauditpage?: boolean }>`
  margin-bottom: ${(props) => (!props.$isauditpage ? '100px' : '')};
`;
