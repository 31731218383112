import React, { useEffect, useMemo } from 'react';
import Paper from '@mui/material/Paper';
import { styled as MuiStyled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import { Pagination } from '@equally-ai-front/common/src/components/pagination';
import {
  OrderOptionsEnum,
  usePagination,
} from '@equally-ai-front/common/src/hooks/use-pagination';
import { ScannedPagesSummary } from './scanned-pages-summary';
import { useFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-filter';
import { useAddNewFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-add-new-filter';
import { MultiFilterSelect } from '@equally-ai-front/common/src/components/filters/multi-filter-select';
import styled from 'styled-components';
import { Close } from '@mui/icons-material';
import { ScansSummary } from './scan-summary-count-tooltip';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import { DomainRow } from './domain-row';
import {
  getWebsiteScanDetails,
  latestDomainScansDetailFields,
  latestDomainScansDetailsMap,
  latestDomainScansFields,
  latestDomainScansResultMap,
} from '../../containers/websites-container/consts';
import { LatestScanTotals } from '@equally-ai-front/common/src/types/scans';
import { CsvExport } from './csv-export';

export type LatestDomainScansFieldMap = {
  key: string;
  align?: 'left' | 'center' | 'right';
  format: (row: any, key: string) => string | React.ReactNode;
  displayName?: string;
};

export interface LatestScansSummary extends ScansSummary {
  previousError: number;
  previousWarning: number;
  previousNotice: number;
}

export interface LatestDomainScansData {
  domainName: string;
  scannedPages: LatestScansSummary[];
  scannedPagesCount: number;
  latestScanTotals: LatestScanTotals;
  accessibilityScore: number;
  previousAccessibilityScore?: number;
  createdAt?: string;
  paid?: boolean;
}

interface WebsitesTableProps {
  tableData: LatestDomainScansData[];
}

const DOMAINS_TABLE_PAGE_LIMIT = 10;

export const WebsitesTable = (props: WebsitesTableProps) => {
  const { tableData } = props;

  const formattedTableData = useMemo(
    () =>
      tableData.map((data) => {
        const {
          accessibilityScore,
          domainName,
          scannedPages,
          scannedPagesCount,
          latestScanTotals,
          createdAt,
          paid,
          previousAccessibilityScore,
        } = data;

        return {
          accessibilityScore,
          previousAccessibilityScore,
          createdAt,
          paid,
          domainName,
          scannedPages,
          scannedPagesCount,
          latestScanTotals,
          totalErrors: latestScanTotals.currentTotals.totalErrors,
          totalWarnings: latestScanTotals.currentTotals.totalWarnings,
          totalNotices: latestScanTotals.currentTotals.totalNotices,
        };
      }),
    [tableData],
  );

  const {
    visibleData,
    allFilterOptions,
    filteredOptions,
    searchTerm,
    filters,
    removeFilterType,
    setSearchTerm,
    handleSearchInputChange,
    addFilter,
  } = useFilter({
    filterOptions: latestDomainScansFields,
    data: formattedTableData,
  });

  const {
    handleOptionClick,
    handleFilterValueChange,
    handleFilterSecondValueChange,
    newFilter,
    isFilterFulfilled,
    handleFilterOperatorChange,
    filterValueOptions,
  } = useAddNewFilter({ allFilterOptions, visibleData });

  const {
    pageCount,
    currentData: visibleDomainScanData,
    handlePageChange,
    currentPage,
    searchTerm: domainNameSearchValue,
    handleSearch,
    handleSort,
    sortOptions,
  } = usePagination(visibleData, DOMAINS_TABLE_PAGE_LIMIT, 'domainName', {
    createdAt: OrderOptionsEnum.DESC,
  });

  return (
    <div>
      <FilterSearchWrapper>
        <Box sx={{ display: 'flex' }}>
          <CsvExport latestDomainScansData={visibleData} />
          <MultiFilterSelect
            filterValueOptions={filterValueOptions}
            handleFilterValueChange={handleFilterValueChange}
            handleFilterSecondValueChange={handleFilterSecondValueChange}
            handleFilterOperatorChange={handleFilterOperatorChange}
            searchTerm={searchTerm}
            addFilter={addFilter}
            isFilterFulfilled={isFilterFulfilled}
            setSearchTerm={setSearchTerm}
            handleOptionClick={handleOptionClick}
            handleSearchInputChange={handleSearchInputChange}
            options={filteredOptions}
            currentFilter={newFilter}
          />
        </Box>
        <FormControl>
          <WebsitesSearchInput
            type="text"
            value={domainNameSearchValue}
            onChange={(evt) => handleSearch(evt.target.value)}
            id="search-input"
            placeholder="Search"
            variant="outlined"
          />
        </FormControl>
      </FilterSearchWrapper>
      {Object.values(filters).length > 0 && (
        <SelectedFilterWrapper>
          {Object.values(filters).map(
            ({
              key,
              displayName,
              filterType,
              filterCondition,
              value,
              value2,
            }) => (
              <SelectedFilterTag key={key}>
                <FilterTag>
                  <TagLabel>{displayName}:</TagLabel>
                  <TagValue>{value}</TagValue>
                  {value2 && (
                    <TagValue style={{ marginLeft: '4px' }}>
                      &nbsp;and&nbsp;
                      {value2}
                    </TagValue>
                  )}
                </FilterTag>
                <FilterTag>
                  <TagLabel>Operator:</TagLabel>
                  <TagValue>
                    {filterCondition && filterCondition.replaceAll('_', ' ')}
                  </TagValue>
                </FilterTag>
                <RemoveTag onClick={() => removeFilterType(key)} />
              </SelectedFilterTag>
            ),
          )}
        </SelectedFilterWrapper>
      )}
      <TableContainer component={Paper}>
        <Table aria-label="websites table">
          <TableHead>
            <TableRow>
              <TableCell />
              {Object.entries(latestDomainScansFields).map(
                ([_, DomainFieldDetail]) => {
                  const currentSortKey = Object.keys(sortOptions)[0];
                  const currentSortOrder =
                    Object.values(sortOptions)[0] || 'desc';

                  return (
                    <TableCell key={DomainFieldDetail.key}>
                      <TableSortLabel
                        active={currentSortKey === DomainFieldDetail.key}
                        direction={currentSortOrder}
                        onClick={() => handleSort(DomainFieldDetail.key)}
                      >
                        {DomainFieldDetail.displayName}
                      </TableSortLabel>
                    </TableCell>
                  );
                },
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleDomainScanData.map((scanData, sdidx) => {
              const domainScanDetails = getWebsiteScanDetails(
                scanData?.scannedPages,
              );
              return (
                <DomainRow
                  key={`${scanData.domainName}-${sdidx}`}
                  latestDomainScansResultMap={latestDomainScansResultMap}
                  latestDomainScansData={scanData}
                  latestDomainScansDetailsMap={latestDomainScansDetailsMap}
                  domainDetailsTitle="Domain Summary"
                  domainDetailFields={latestDomainScansDetailFields}
                  domainDetails={domainScanDetails}
                  emptyDomainDetailsText="No page scanned yet."
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination handlePageChange={handlePageChange} pageCount={pageCount} />
    </div>
  );
};

const SelectedFilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 2px solid rgb(228, 231, 235);
  border-radius: 8px;
  padding: 10px;
  margin: 20px 0;
`;

const SelectedFilterTag = styled.p`
  border-radius: 16px;
  background-color: rgb(245, 247, 250);
  position: relative;
  margin: 10px 10px 10px 0;
  padding: 7px 28px 7px 12px;
  font-size: 12px;
`;

const FilterTag = styled.span`
  margin: 0;
  display: block;
`;

const TagLabel = styled.span`
  font-weight: 500;
  margin-right: 4px;
`;

const TagValue = styled.span``;

const RemoveTag = styled(Close)`
  color: red;
  position: absolute;
  font-size: 15px !important;
  top: 2px;
  right: 10px;
  cursor: pointer;
`;

const FilterSearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  padding-top: 20px;
`;

const WebsitesSearchInput = MuiStyled(TextField)({
  '.MuiOutlinedInput-input': {
    height: 10,
  },
});
