import React, { useMemo } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
} from 'recharts';
import { useAppSelector } from 'store/hooks';
import { Domain } from 'store/slice/domains-slice';
import styled from 'styled-components';
import { CustomScroll } from 'components/css-components';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const formatBarChartTooltipText = (inputDate: string): string => {
  const [month, shortYear] = inputDate.split('/').map(Number);

  if (isNaN(month) || isNaN(shortYear)) {
    return 'Invalid Date';
  }

  const currentYear = new Date().getFullYear();
  const fullYear = shortYear + (currentYear - (currentYear % 100));
  const formattedDate = `${months[month - 1]} ${fullYear}`;
  return formattedDate;
};

interface DomainSummary {
  count: number;
  percentage?: number;
}

interface MonthDomainInfo {
  date: string;
  domainIncrease: DomainSummary;
  newDomains: DomainSummary;
  totalDomains: DomainSummary;
}

interface DomainGraphData {
  [key: string]: MonthDomainInfo;
}

const getDomainsGraphData = (data: Domain[]): MonthDomainInfo[] => {
  const domainGraphData: DomainGraphData = {};
  const domainsList = [...data];

  domainsList.sort((a, b) => a.registrationDate - b.registrationDate);
  domainsList.forEach((entry) => {
    const registrationDate = new Date(entry.registrationDate * 1000);
    const fullYear = registrationDate.getFullYear();
    const shortYear = fullYear.toString().slice(-2);
    const month = registrationDate.getMonth() + 1;
    const key = `${month}/${shortYear}`;

    domainGraphData[key] = domainGraphData[key] || {
      domainIncrease: {
        count: 0,
        percentage: 0,
      },
      newDomains: {
        count: 0,
      },
      totalDomains: {
        count: 0,
      },
    };
    domainGraphData[key].newDomains.count++;
  });

  const domainsGraphDataList = Object.entries(domainGraphData);

  let totalDomainsTillDate = 0;
  return domainsGraphDataList.map(([date, { newDomains }], index) => {
    const [, previousMonthDomainInfo] =
      domainsGraphDataList[index > 0 ? index - 1 : 0];
    const domainIncreaseCount = Math.max(
      0,
      newDomains.count - previousMonthDomainInfo.newDomains.count,
    );

    const percentage =
      previousMonthDomainInfo.newDomains.count === 0
        ? 100
        : (domainIncreaseCount / previousMonthDomainInfo.newDomains.count) *
          100;
    totalDomainsTillDate += newDomains.count;
    return {
      date,
      domainIncrease: {
        count: domainIncreaseCount,
        percentage,
      },
      newDomains,
      totalDomains: {
        count: totalDomainsTillDate,
      },
    };
  });
};

const getSquarePath = (
  x: number,
  y: number,
  width: number,
  height: number,
  borderRadius: number,
) => {
  return `M${x},${y + borderRadius}
      L${x},${y + height - borderRadius}
      Q${x},${y + height} ${x + borderRadius},${y + height}
      L${x + width - borderRadius},${y + height}
      Q${x + width},${y + height} ${x + width},${y + height - borderRadius}
      L${x + width},${y + borderRadius}
      Q${x + width},${y} ${x + width - borderRadius},${y}
      L${x + borderRadius},${y}
      Q${x},${y} ${x},${y + borderRadius}
      Z`;
};

const RoundedBar = (props: any) => {
  const { fill, x, y, width, height } = props;
  return (
    <path d={getSquarePath(x, y, width, height, 8)} stroke="none" fill={fill} />
  );
};

export const DomainsGraph = () => {
  const { domains } = useAppSelector((state) => state.domains);
  const domainsGraphData = useMemo(() => {
    if (domains.length === 0) {
      return [];
    }

    return getDomainsGraphData(domains);
  }, [domains]);

  if (domains.length === 0) {
    return <></>;
  }

  return (
    <Wrapper>
      <BarChart width={800} height={200} data={domainsGraphData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          tick={{ fontSize: 14 }}
          label={{ fontSize: 14 }}
        />
        <YAxis tick={{ fontSize: 14 }} label={{ fontSize: 14 }} />
        <Tooltip
          labelFormatter={(label) => {
            return `${formatBarChartTooltipText(label)}`;
          }}
          formatter={(_, name, props) => {
            const { domainIncrease, newDomains, totalDomains } =
              props.payload as MonthDomainInfo;

            const domainSummaryMap: Record<string, JSX.Element> = {
              'domainIncrease.count': (
                <TooltipText>
                  Increase from last month ({domainIncrease.percentage}%) +
                  {domainIncrease.count}
                </TooltipText>
              ),
              'newDomains.count': (
                <div>
                  <TooltipText>New Domains: {newDomains.count}</TooltipText>
                  <TotalDomainsTooltipText>
                    Total Domains: {totalDomains.count}
                  </TotalDomainsTooltipText>
                </div>
              ),
            };
            return [domainSummaryMap[name]];
          }}
        />
        <Bar
          dataKey="domainIncrease.count"
          fill="#baefff"
          barSize={25}
          shape={<RoundedBar />}
        >
          <Cell fill="#baefff" />
        </Bar>
        <Bar
          dataKey="newDomains.count"
          fill="#115dfc"
          barSize={25}
          shape={<RoundedBar />}
        >
          <Cell fill="#115dfc" />
        </Bar>
      </BarChart>
    </Wrapper>
  );
};

const Wrapper = styled(CustomScroll)`
  overflow-x: auto;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
  margin: 30px 0 20px;
`;

const TooltipText = styled.p`
  margin: 0;
`;

const TotalDomainsTooltipText = styled.p`
  color: #454284;
  margin: 9px 0 0;
`;
