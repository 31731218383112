import styled from '@emotion/styled';
import { Box } from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { scrollToTop } from '../../hooks';
import { AuditView } from './audit-view';
import { Issue, Scan } from '@equally-ai-front/common/src/types/issues';
import { buildDevtoolsReport } from '@equally-ai-front/common/src/helpers/templates';
import { StartFreeTrial } from './start-free-trial';
import { WhatCustomersGet } from './what-customers-get';
import { BookADemo } from './book-a-demo';
import { AuditDomainInfo } from '@equally-ai-front/common/src/types/domains';
// import { AddWidgetBanner } from './add-widget-banner';

interface AuditReportProps {
  allowNonBusiness?: boolean;
  onStartFreeTrialClick: () => void;
  performAudit: (
    url: string,
    businessId: number,
    websiteId?: number,
  ) => Promise<{ scan: any; isSuccess: boolean }>;
  isLoading: boolean;
  getIssues: (scan: Scan) => Promise<Issue[]>;
  scans: Scan[];
  validateBeforeAudit?: () => boolean;
  validateBeforeAuditCallback?: () => void;
  isAdmin?: boolean;
  isUserPanel?: boolean;
  getDomainInfo?: (domainUrl: string) => Promise<AuditDomainInfo | null>;
}

export const AuditReport = (props: AuditReportProps) => {
  const {
    allowNonBusiness = false,
    onStartFreeTrialClick,
    getIssues,
    performAudit,
    isLoading: loading,
    scans,
    validateBeforeAudit,
    validateBeforeAuditCallback,
    isAdmin,
    isUserPanel,
    getDomainInfo,
  } = props;
  const currentScanRequestIdRef = useRef<string | null>(null);
  const currentScan = useMemo(() => {
    if (scans.length === 0) {
      return null;
    }
    return scans.filter(
      (scan) => scan.request_id === currentScanRequestIdRef.current,
    )?.[0];
  }, [scans, currentScanRequestIdRef, loading]);
  const currentBusiness = useSelector(
    (state: any) => state.business?.currentBusiness,
  );
  const [issues, setIssues] = useState<Issue[]>([]);
  const [domainInfo, setDomainInfo] = useState<AuditDomainInfo | null>(null);

  useEffect(() => {
    if (!currentScan) {
      return;
    }

    const get = async () => {
      const issues = await getIssues(currentScan);
      setIssues(issues);
    };
    if (currentScan?.status === 'SUCCESS') {
      void get();
      currentScanRequestIdRef.current = null;
    }
  }, [currentScan]);

  const report = useMemo(() => {
    if (issues.length === 0) {
      return {
        reportGroups: {
          basic: [],
          advanced: [],
          potential: [],
          all: [],
        },
        list: {
          basic: [],
          advanced: [],
          potential: [],
          all: [],
        },
        reportList: [],
        pdfReport: [],
        websiteUrl: '',
        websitePath: '',
        percentages: {
          basic: 0,
          advanced: 0,
          potential: 0,
        },
      };
    }
    return buildDevtoolsReport(issues?.[0]?.domain_url || '', issues);
  }, [issues]);

  const onAudit = useCallback(
    (url: string, cb?: (url: string, currentIssues: Issue[]) => void) => {
      if (!currentBusiness && !allowNonBusiness) {
        return;
      }
      if (validateBeforeAudit && !validateBeforeAudit()) {
        validateBeforeAuditCallback && validateBeforeAuditCallback();
        return;
      }

      async function auditPerform() {
        const response = await performAudit(url, currentBusiness?.id, 0);
        const auditDomainInfo = await getDomainInfo?.(
          url.replace(/^https?:\/\/([^\/]+).*/, '$1'),
        );
        if (auditDomainInfo) {
          setDomainInfo(auditDomainInfo);
        }
        // @ts-ignore
        const { scan, isSuccess } = response?.[0];
        if (!isSuccess) {
          return;
        }
        currentScanRequestIdRef.current = scan.request_id;
        // setIssues(currentIssues);
        // cb && cb(url, currentIssues);
        scrollToTop();
      }

      void auditPerform();
    },
    [performAudit, currentBusiness],
  );

  return (
    <>
      <Container>
        <AuditView
          handleAudit={onAudit}
          loading={loading}
          report={report}
          isWidgetAppeared={currentScan?.is_widget_appeared || false}
          scanImageUrl={currentScan?.scan_image_url || ''}
          currentScan={currentScan}
          onStartFreeTrialClick={onStartFreeTrialClick}
          isAdmin={isAdmin}
          domainInfo={domainInfo}
        />
      </Container>
      {report.websiteUrl && !isAdmin && !loading && (
        <>
          <WhatCustomersGet />
          <BookADemo />
        </>
      )}
      {currentScan?.is_widget_appeared === false &&
        report.websiteUrl &&
        !isAdmin &&
        !loading && (
          <StartFreeTrial
            onStartFreeTrialClick={onStartFreeTrialClick}
            isUserPanel={isUserPanel}
          />
        )}
    </>
  );
};

const Container = styled(Box)`
  overflow: hidden;
`;
