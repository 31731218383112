import { Box, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ChatIcon } from '../../assets/chat-icon.svg';
import { AuditReportSummaryCard } from './styles';
import { ReportData } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { PrimaryButton } from '../styled-components';
import { styled as MuiStyled } from '@mui/material/styles';
import { buttonClasses } from '@mui/material/Button';
import { IssuesSummaryByCategory } from './issues-summary-by-category';

interface HighRiskAccessibilitySummaryProps {
  report: ReportData;
  onStartFreeTrialClick: () => void;
}

export const HighRiskAccessibilitySummary = (
  props: HighRiskAccessibilitySummaryProps,
) => {
  const { report, onStartFreeTrialClick } = props;
  return (
    <Wrapper>
      <InfoSection>
        <InfoSectionContent>
          <InfoSectionContentHeader>
            <InfoSectionContentIconWrapper>
              <ChatIcon />
            </InfoSectionContentIconWrapper>
            <InfoSectionContentText>
              We will scan and resolve your whole website issues after you have
              upgraded your account
            </InfoSectionContentText>
          </InfoSectionContentHeader>
          <ButtonWrapper>
            <Button onClick={onStartFreeTrialClick}>Start for free</Button>
          </ButtonWrapper>
        </InfoSectionContent>
        <InfoSectionIssues>
          <InfoSectionIssuesTitle>Issues on page</InfoSectionIssuesTitle>
          <InfoSectionIssuesCount>
            {report.list.all.length}
          </InfoSectionIssuesCount>
        </InfoSectionIssues>
      </InfoSection>
      <IssuesSummarySection>
        <IssuesSummaryBySeverity>
          <Title>Issue severity</Title>
          <IssuesSeverityWrapper>
            <IssuesSeverity $bgcolor="#F19D9B">
              <IssuesSeverityLabel>Critical</IssuesSeverityLabel>
              <IssuesSeverityValue>
                {report.list.basic.length}
              </IssuesSeverityValue>
            </IssuesSeverity>
            <IssuesSeverity $bgcolor="#F1DD99">
              <IssuesSeverityLabel>Potential</IssuesSeverityLabel>
              <IssuesSeverityValue>
                {report.list.advanced.length}
              </IssuesSeverityValue>
            </IssuesSeverity>
            <IssuesSeverity $bgcolor="#B8E8D6">
              <IssuesSeverityLabel>Best Practice</IssuesSeverityLabel>
              <IssuesSeverityValue>
                {report.list.potential.length}
              </IssuesSeverityValue>
            </IssuesSeverity>
          </IssuesSeverityWrapper>
        </IssuesSummaryBySeverity>
        <Separator />
        <IssuesSummaryByCategory
          report={report}
          title="Issues detected by category"
        />
      </IssuesSummarySection>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  margin-top: 22px;
`;

const InfoSection = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
`;

const InfoSectionContent = styled(AuditReportSummaryCard)`
  width: 100%;
  max-width: 590px;
  height: 188px;

  @media screen and (max-width: 1300px) {
    max-width: 65%;
  }

  @media screen and (max-width: 650px) {
    max-width: 100%;
  }
`;

const InfoSectionContentHeader = styled(Box)`
  display: flex;
`;

const InfoSectionContentIconWrapper = styled(Box)``;

const InfoSectionContentText = styled(Typography)`
  width: 503px;
  height: 60px;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #000a14d9;
  margin-left: 16px;

  @media screen and (max-width: 1024px) {
    font-size: 14px;
    line-height: 21px;
    width: fit-content;
  }
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const Button = MuiStyled(PrimaryButton)(() => ({
  [`&.${buttonClasses.root}`]: {
    width: 146,
    height: 48,
    borderRadius: 8,
    whiteSpace: 'nowrap',
    padding: '12px 24px',
    backgroundColor: '#454284',
    color: '#ffffff',
    lineHeight: 24,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 500,
  },
}));

const InfoSectionIssues = styled(AuditReportSummaryCard)`
  width: 100%;
  max-width: 302px;
  height: 188px;

  @media screen and (max-width: 1300px) {
    max-width: 33%;
  }

  @media screen and (max-width: 650px) {
    max-width: 100%;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const InfoSectionIssuesTitle = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  color: #000a14d9;
  width: 173px;
  height: 36px;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
    line-height: 27px;
  }

  @media screen and (max-width: 650px) {
    text-align: center;
  }
`;

const InfoSectionIssuesCount = styled(Typography)`
  width: 254px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 93px;
  font-size: 62px;
  font-weight: 700;
  line-height: 93px;
  text-align: left;
  color: #000a14d9;

  @media screen and (max-width: 1120px) {
    width: -webkit-fill-available;
  }

  @media screen and (max-width: 1024px) {
    font-size: 55px;
    line-height: 82.5px;
  }

  @media screen and (max-width: 650px) {
    text-align: center;
  }
`;

const IssuesSummarySection = styled(AuditReportSummaryCard)`
  display: flex;
  margin-top: 30px;

  @media screen and (max-width: 890px) {
    flex-direction: column;
    padding: 20px 14px;
  }
`;

const IssuesSummaryBySeverity = styled(Box)`
  width: 170px;

  @media screen and (max-width: 650px) {
    width: 100%;
  }
`;

const Title = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  color: #000a14d9;
  width: 163px;
  height: 36px;

  @media screen and (max-width: 890px) {
    width: 136px;
    height: 30px;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
  }
`;

const IssuesSeverityWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 170px;
  margin-top: 55px;
  gap: 16px;

  @media screen and (max-width: 890px) {
    margin-top: 20px;
  }

  @media screen and (max-width: 650px) {
    width: 100%;
  }
`;

const IssuesSeverity = styled(Box)<{ $bgcolor: string }>`
  background-color: ${(props) => props.$bgcolor};
  width: 170px;
  height: 46px;
  padding: 6px 16px 6px 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 650px) {
    width: 100%;
  }
`;

const IssuesSeverityLabel = styled(Typography)`
  width: 97px;
  height: 24px;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  color: #000a14;
`;

const IssuesSeverityValue = styled(Typography)`
  height: 24px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #000a14;
`;

const Separator = styled(Box)`
  background-color: #e4e7eb;
  width: 2px;
  height: 296px;
  margin: 56px 74px 0 75px;

  @media screen and (max-width: 1080px) {
    margin: 56px 35px 0;
  }

  @media screen and (max-width: 890px) {
    height: 2px;
    width: 100%;
    max-width: 80%;
    margin: 40px auto;
  }

  @media screen and (max-width: 650px) {
    max-width: 90%;
  }
`;
