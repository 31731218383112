import React from 'react';
import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/styles';
import { LatestDomainScansFieldMap } from './websites-table';
import { FilterField } from '@equally-ai-front/common/src/components/filters/types';

interface DomainDetailsRowProps<T> {
  isDomainDetailsOpen: boolean;
  latestDomainScansDetailsMap: LatestDomainScansFieldMap[];
  domainDetailsTitle: string;
  domainDetailFields: Record<string, FilterField>;
  domainDetails: T[];
  emptyDomainDetailsText: string;
}

export const DomainDetailsRow = <T,>(props: DomainDetailsRowProps<T>) => {
  const {
    isDomainDetailsOpen,
    latestDomainScansDetailsMap,
    domainDetailsTitle,
    domainDetailFields,
    domainDetails,
    emptyDomainDetailsText,
  } = props;

  return (
    <>
      <TableRow>
        <TableCell
          sx={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={latestDomainScansDetailsMap.length}
        >
          <Collapse in={isDomainDetailsOpen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                sx={{ fontSize: '16px' }}
              >
                {domainDetailsTitle}
              </Typography>
              <Table aria-label={domainDetailsTitle}>
                <TableHead>
                  <TableRow>
                    {Object.entries(domainDetailFields).map(
                      ([_, domainDetailField], dfdidx) => (
                        <StyledTableCell key={dfdidx}>
                          {domainDetailField.displayName}
                        </StyledTableCell>
                      ),
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {domainDetails.length > 0 ? (
                    domainDetails.map((details, dpsidx) => {
                      return (
                        <TableRow key={`domain-details-table-row-${dpsidx}`}>
                          {latestDomainScansDetailsMap.map((item, index) => (
                            <StyledTableCell
                              key={`domain-details-table-cell-${index}`}
                              align={item.align}
                            >
                              {item.format(details, item.key)}
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <StyledTableCell
                        colSpan={Object.entries(domainDetailFields).length}
                        align="center"
                      >
                        {emptyDomainDetailsText}
                      </StyledTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  whiteSpace: 'nowrap',
}));
