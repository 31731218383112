import { TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import MUITable from '@mui/material/Table';
import { Pagination } from '@equally-ai-front/common/src/components/pagination';
import { usePagination } from '@equally-ai-front/common/src/hooks/use-pagination';
import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import styled from 'styled-components';
import { DomainsTableBody } from './domains-table-body';
import { domainFields as itemFields } from './consts';
import { withStyles } from '@mui/styles';
import { useFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-filter';
import { useAddNewFilter } from '@equally-ai-front/common/src/components/filters/hooks/use-add-new-filter';
import { DomainsPageTableHeader } from './domains-page-table-header';

const DOMAIN_TABLE_PAGE_LIMIT = 50;

interface DomainsTableProps {
  currentTab: number;
  onTabChange: (evt: React.SyntheticEvent, newValue: number) => void;
}

export const DomainsTable = (props: DomainsTableProps) => {
  const { currentTab, onTabChange } = props;
  const { domains } = useAppSelector((state) => state.domains);
  const {
    visibleData,
    allFilterOptions,
    filteredOptions,
    searchTerm,
    filters,
    removeFilterType,
    setSearchTerm,
    handleSearchInputChange,
    addFilter,
  } = useFilter({ filterOptions: itemFields, data: domains });

  const {
    handleOptionClick,
    handleFilterValueChange,
    handleFilterSecondValueChange,
    newFilter,
    isFilterFulfilled,
    handleFilterOperatorChange,
    filterValueOptions,
  } = useAddNewFilter({ allFilterOptions, visibleData });

  const {
    pageCount,
    currentData,
    handlePageChange,
    handleSort,
    sortOptions,
    searchTerm: domainSearchValue,
    handleSearch,
  } = usePagination(visibleData, DOMAIN_TABLE_PAGE_LIMIT, 'domainName');

  return (
    <Wrapper>
      <DomainsPageTableHeader
        currentTab={currentTab}
        onTabChange={onTabChange}
        filteredOptions={filteredOptions}
        searchTerm={searchTerm}
        filters={filters}
        removeFilterType={removeFilterType}
        setSearchTerm={setSearchTerm}
        handleSearchInputChange={handleSearchInputChange}
        addFilter={addFilter}
        handleOptionClick={handleOptionClick}
        handleFilterValueChange={handleFilterValueChange}
        handleFilterSecondValueChange={handleFilterSecondValueChange}
        newFilter={newFilter}
        isFilterFulfilled={isFilterFulfilled}
        handleFilterOperatorChange={handleFilterOperatorChange}
        filterValueOptions={filterValueOptions}
        domainSearchValue={domainSearchValue}
        handleSearch={handleSearch}
        currentDomainsData={visibleData}
      />
      <TableContainer>
        <DomainsTableComponent
          sx={{ minWidth: 650, tableLayout: 'fixed' }}
          aria-label="domains table"
          stickyHeader
        >
          <DomainsTableHead>
            <DomainsTableRow>
              {Object.values(itemFields).map((itemField) => (
                <CustomTableHeadCell
                  key={itemField.key}
                  style={{
                    maxWidth: '200px',
                  }}
                >
                  <TableHeaderCell
                    onClick={() => {
                      handleSort(itemField.key);
                    }}
                  >
                    <span>{itemField.displayName}</span>
                    <TableSortIconWrapper
                      $isvisible={sortOptions[itemField.key] ? 'true' : 'false'}
                    >
                      {sortOptions[itemField.key] ? (
                        sortOptions[itemField.key] === 'desc' ? (
                          <SouthIcon style={{ width: '65%' }} />
                        ) : (
                          <NorthIcon style={{ width: '65%' }} />
                        )
                      ) : (
                        <NorthIcon style={{ width: '65%' }} />
                      )}
                    </TableSortIconWrapper>
                  </TableHeaderCell>
                </CustomTableHeadCell>
              ))}
            </DomainsTableRow>
          </DomainsTableHead>
          <DomainsTableBody currentData={currentData} />
        </DomainsTableComponent>
      </TableContainer>
      <Pagination pageCount={pageCount} handlePageChange={handlePageChange} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  border-radius: inherit;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 0;
  overflow-y: auto;
  position: relative;
  margin-bottom: 50px;
`;

const DomainsTableComponent = styled(MUITable)`
  width: 100%;
  max-width: 100%;
  table-layout: auto;
`;

const DomainsTableHead = styled(TableHead)`
  border-top: none;
  border-bottom: 2px solid #f4f3fe;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  display: table;
  width: 100%;
  table-layout: fixed;
`;

const CustomTableHeadCell = withStyles((theme) => ({
  head: {
    verticalAlign: 'bottom',
    border: '2px solid #f5f7fa',
    borderBottom: 'none',
    borderTop: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 'bold',
    fontSize: '14px',
    width: '200px',
    whiteSpace: 'nowrap',
  },
}))(TableCell);

export const DomainsTableRow = withStyles((theme) => ({
  root: {
    width: '100%',
    tableLayout: 'fixed',
    display: 'table',

    '&:last-child td, &:last-child th': {
      borderTop: '1px solid #f4f3fe',
      borderRight: '2px solid #f5f7fa',
      borderLeft: '2px solid #f5f7fa',
    },
  },
}))(TableRow);

const TableSortIconWrapper = styled.span<{ $isvisible: 'true' | 'false' }>`
  display: flex;
  align-items: center;
  margin-left: 2px;
  width: 25px;
  height: 25px;
  opacity: ${(p) => (p.$isvisible === 'true' ? '1' : '0')};
`;

const TableHeaderCell = styled.p`
  display: flex;
  align-items: center;
  height: 30px;
  margin: 0;
  cursor: pointer;
  width: fit-content;

  &:hover ${TableSortIconWrapper} {
    opacity: 0.5;
  }
`;
