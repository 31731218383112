import React from 'react';
import styled from 'styled-components';
import ScanIcon from '../../assets/scan-icon-bg-white.svg';
import { AccessilitySummary } from '../accessibility-summary/accessibility-summary';
import { ReportData } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { AuditComplianceType } from '../accessibility-summary/audit-compliance-info';
import { AuditDomainInfo } from '@equally-ai-front/common/src/types/domains';

interface AuditViewHeaderProps {
  report: ReportData;
  loading: boolean;
  scanImageUrl: string;
  isWidgetAppeared: boolean;
  auditComplianceType: AuditComplianceType;
  domainInfo: AuditDomainInfo | null;
}

export const AuditViewHeader = (props: AuditViewHeaderProps) => {
  const {
    report,
    loading,
    scanImageUrl,
    isWidgetAppeared,
    auditComplianceType,
    domainInfo,
  } = props;
  return (
    <Wrapper>
      {loading ? (
        <AuditViewHeaderContainer>
          <ScanIconWrapper className="skeleton skeleton-text">
            <ScanImgIcon src={ScanIcon} />
          </ScanIconWrapper>
          <ScanningTextWrapper>
            <Title>Scanning your website</Title>
            <Text>Preparing your report</Text>
          </ScanningTextWrapper>
        </AuditViewHeaderContainer>
      ) : (
        <Container>
          <HeaderInfoWrapper>
            <AccessilitySummary
              isWidgetAppeared={isWidgetAppeared}
              report={report}
              loading={loading}
              scanImageUrl={scanImageUrl}
              auditComplianceType={auditComplianceType}
              domainInfo={domainInfo}
            />
          </HeaderInfoWrapper>
        </Container>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 47.5px 0 19px;

  @media screen and (max-width: 1280px) {
    padding: 0 19px;
  }

  @media screen and (max-width: 1080px) {
    padding: 0 35px 0 35px;
  }
`;

const AuditViewHeaderContainer = styled.div`
  max-width: 90%;
  display: flex;
  justify-content: space-between;

  @media (pointer: none), (pointer: coarse) {
    flex-direction: column-reverse;
  }
`;

const ScanIconWrapper = styled.div`
  flex-basis: 40%;
`;

const ScanImgIcon = styled.img`
  width: 100%;
`;

const Container = styled.div``;

const HeaderInfoWrapper = styled.div``;

const ScanningTextWrapper = styled.div`
  margin-top: 20px;

  @media screen and (max-width: 1030px) {
    margin-top: 5px;
  }

  @media (pointer: none), (pointer: coarse) {
    text-align: center;
  }
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 0;
`;

const Text = styled.p`
  font-weight: 300;
  font-size: 16px;
  margin-top: 0;
`;
