import styled from '@emotion/styled';
import { Tabs as MuiTabs, Tab as MuiTab } from '@mui/material';

export const StyledTabs = styled(MuiTabs)`
  background: #f5f7fa;
  border-radius: 24px;

  @media screen and (max-width: 600px) {
    padding: 0;
  }

  & .MuiTab-root {
    max-width: initial;
  }

  & .MuiTabs-scroller {
    z-index: 0;
  }

  & .MuiTabs-indicator {
    background: #ffffff;

    border: 2px solid #cbd2d9;
    box-sizing: border-box;
    border-radius: 24px;

    height: inherit;
    width: inherit;

    top: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: -1;
  }

  & .MuiTabs-flexContainer {
    flex-direction: row;
  }

  width: 100%;
  @media screen and (min-width: 600px) {
    border-radius: 48px;
    width: initial;
  }

  @media screen and (max-width: 600px) {
    & .MuiTab-root {
      flex: 1;
      flex-direction: row;
    }
  }
`;

export const StyledTab = styled(MuiTab)`
  /* min-width: 200px; */

  @media screen and (max-width: 600px) {
    max-width: 40%;
  }
`;
