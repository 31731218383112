interface SocialMediaShareElement {
  icon: React.ReactNode;
  link: string;
}

export const getSocialMediaShareElements = (
  url: string,
  facebookIcon: React.ReactNode,
  linkedInIcon: React.ReactNode,
  twitterIcon: React.ReactNode,
): SocialMediaShareElement[] => {
  const text = 'My site is now accessible, following WCAG standards';
  const encodedUrl = encodeURIComponent(url);
  const encodedText = encodeURIComponent(text);

  return [
    {
      icon: facebookIcon,
      link: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
    },
    {
      icon: linkedInIcon,
      link: `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`,
    },
    {
      icon: twitterIcon,
      link: `https://twitter.com/intent/tweet?text=${encodedText}&url=${encodedUrl}`,
    },
  ];
};
