import React from 'react';
import { ReactComponent as HighRiskIcon } from '../../assets/high-risk-icon.svg';
import { ReactComponent as InProgressIcon } from '../../assets/in-progress-icon.svg';
import { ReactComponent as LowRiskIcon } from '../../assets/low-risk-icon.svg';
import DesktopSkeleton from '../../assets/desktop-audit-view-skeleton.svg';
import MobileSkeleton from '../../assets/mobile-audit-view-skeleton.svg';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { useMediaQuery } from '@equally-ai-front/common/src/hooks/useMediaQuery';
import {
  Container,
  SkeletonImage,
  SkeletonImageWrapper,
  PdfDownloadButtonContainer,
  TextDataContent,
  AuditComplianceInfoWrapper,
  AuditComplianceInfoDataWrapper,
  TextDataAndIconWrapper,
  DataIconWrapper,
  TextDataWrapper,
  TextDataTitle,
  DataIconWrapperMobile,
  TextDataTileAndIconWrapper,
} from './styles';
import DownloadIcon from '../../assets/download.svg';
import { styleConsts } from './partially-compliant';
import { ScanImage } from './scan-image';
import { PdfDownloadButton } from '../PdfDownloadButton';
import { AuditDomainInfo } from '@equally-ai-front/common/src/types/domains';

export type AuditComplianceType = 'high-risk' | 'in-progress' | 'low-risk';

interface AuditComplianceData {
  icon: React.ReactElement;
  title: string;
  text: React.ReactElement;
}

interface AuditComplianceInfoProps {
  loading: boolean;
  issues: DevtoolsSolution[];
  scanImageUrl: string;
  auditComplianceType: AuditComplianceType;
  domainInfo: AuditDomainInfo | null;
  isWidgetAppeared: boolean;
}

const auditComplianceInfoData: Record<
  AuditComplianceType,
  AuditComplianceData
> = {
  'high-risk': {
    icon: <HighRiskIcon />,
    title: 'High Risk',
    text: (
      <TextDataContent variant="body1">
        We've identified key areas you need to improve your website to ensure it
        is fully accessible to users and compliant with legal standards.
      </TextDataContent>
    ),
  },
  'in-progress': {
    icon: <InProgressIcon />,
    title: 'In Progress',
    text: (
      <TextDataContent variant="body1">
        Please allow up to 48 working hours for an email confirmation that the
        process is complete.
      </TextDataContent>
    ),
  },
  'low-risk': {
    icon: <LowRiskIcon />,
    title: 'Low Risk',
    text: (
      <TextDataContent variant="body1">
        Well done! Your site is now accessible, following WCAG standards.
      </TextDataContent>
    ),
  },
};

export const AuditComplianceInfo = (props: AuditComplianceInfoProps) => {
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');
  const {
    loading,
    issues,
    scanImageUrl,
    auditComplianceType,
    domainInfo,
    isWidgetAppeared,
  } = props;
  const { icon, title, text } = auditComplianceInfoData[auditComplianceType];

  return (
    <Container>
      {loading && (
        <SkeletonImageWrapper className="skeleton skeleton-text">
          <SkeletonImage
            src={isMobile ? MobileSkeleton : DesktopSkeleton}
            alt=""
          />
        </SkeletonImageWrapper>
      )}
      {issues.length > 0 && !loading && (
        <AuditComplianceInfoWrapper>
          <ScanImage scanImageUrl={scanImageUrl} />
          <AuditComplianceInfoDataWrapper>
            <TextDataAndIconWrapper>
              <DataIconWrapper>{icon}</DataIconWrapper>
              <TextDataWrapper>
                <TextDataTileAndIconWrapper>
                  <DataIconWrapperMobile>{icon}</DataIconWrapperMobile>
                  <TextDataTitle variant="h3">{title}</TextDataTitle>
                </TextDataTileAndIconWrapper>
                <>{text}</>
              </TextDataWrapper>
            </TextDataAndIconWrapper>
            {(auditComplianceType === 'high-risk' ||
              auditComplianceType === 'low-risk') && (
              <PdfDownloadButtonContainer>
                <PdfDownloadButton
                  pdfReport={issues}
                  websiteUrl={issues[0].domain_url}
                  styleConsts={styleConsts}
                  iconSrc={DownloadIcon}
                  iconAltText="Download icon"
                  backgroundColor="#454284"
                  borderStyle="none"
                  buttonText="Get the Report"
                  extendedAudit={false}
                  domainInfo={domainInfo}
                  isWidgetAppeared={isWidgetAppeared}
                />
              </PdfDownloadButtonContainer>
            )}
          </AuditComplianceInfoDataWrapper>
        </AuditComplianceInfoWrapper>
      )}
    </Container>
  );
};
