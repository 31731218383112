import { Scan } from '../types/issues';
import { AuditDomainInfo } from '../types/domains';
import { AuditComplianceType } from '../types/audit';
import { ScanTotals } from '../types/scans';
import { isDatePastNumberOfDays } from './domains';
import { ReportData } from '../utils/devToolsTypeConfig';
import { ACCESSIBILITY_MAX_SCORE } from '../utils/constants';
import { calculateAccessibilityScore } from './accessibility-report';

export const sortScans = (scans: Scan[], order: 'asc' | 'desc' = 'asc') => {
  return scans.sort((scanA, scanB) => {
    const timeA = new Date(scanA.created_at).getTime();
    const timeB = new Date(scanB.created_at).getTime();
    return order === 'asc' ? timeA - timeB : timeB - timeA;
  });
};

export const getAuditStatus = (
  isWidgetAppeared: boolean,
  domainInfo: AuditDomainInfo | null,
  report: ReportData,
): AuditComplianceType => {
  let auditComplianceType: AuditComplianceType;
  const scanTotals: ScanTotals = {
    totalErrors: report.list.basic.length,
    totalNotices: report.list.potential.length,
    totalWarnings: report.list.advanced.length,
  };

  const accessibilityScore = calculateAccessibilityScore([scanTotals]) || 0;

  if (
    isWidgetAppeared &&
    domainInfo &&
    !isDatePastNumberOfDays(domainInfo.created_at, 2)
  ) {
    auditComplianceType = 'in-progress';
  } else if (isWidgetAppeared && accessibilityScore > ACCESSIBILITY_MAX_SCORE) {
    auditComplianceType = 'low-risk';
  } else {
    auditComplianceType = 'high-risk';
  }
  return auditComplianceType;
};

export enum ScanStatus {
  pending = 'PENDING',
  processed = 'PROCESSED',
  failed = 'FAILED',
  success = 'SUCCESS',
}
