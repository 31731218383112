import { Typography, Box } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div``;

export const SvgWrapper = styled.div`
  width: 100%;
  max-width: 5.5vw;

  @media screen and (min-width: 1300px) {
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (max-width: 1150px) {
    max-width: 100vw;
    display: flex;
    justify-content: center;
  }
`;

export const TypographyContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  @media screen and (max-width: 1030px) {
    margin-left: 2vw;
  }

  @media screen and (max-width: 1150px) {
    margin-left: 0;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    flex-flow: column nowrap;
    justify-content: center;
    text-align: center;
  }
`;

export const ScanHeaderText = styled(Typography)`
  font-weight: 500;
  margin-top: 6px;

  @media screen and (max-width: 1150px) {
    white-space: nowrap;
    font-size: 28.8px;
    margin-top: -13px;
  }

  @media screen and (max-width: 768px) {
    font-size: 22.4px;
  }
`;

export const ScanText = styled(Typography)`
  margin: 0.3rem 0 2.5rem;
  font-size: 0.95rem;

  @media screen and (max-width: 1150px) {
    margin-bottom: 0;
    text-align: left;
  }
`;

export const ScanHeader = styled(Box)`
  display: flex;

  @media screen and (max-width: 1150px) {
    align-items: center;
    width: 100%;
  }
`;

export const SkeletonImageWrapper = styled.div``;
export const SkeletonImage = styled.img`
  @media screen and (max-width: 1150px) {
    width: 100%;
  }
`;

export const IssueSummaryWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1150px) {
    position: relative;
    height: 420px;
  }
`;

export const IssueSummary = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 90%;

  @media screen and (max-width: 1150px) {
    flex-direction: column-reverse;
    height: 356px;
  }
`;

export const IssueSummaryContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100% - 360px);
  margin-left: 40px;

  @media screen and (max-width: 1150px) {
    max-width: 100%;
    margin-left: 0;
  }
`;

export const IssueSummaryContent = styled.div`
  display: flex;

  @media screen and (max-width: 1150px) {
    margin-left: 0;
    flex-direction: column;
    margin-bottom: 30px;
    align-items: center;
  }
`;

export const PdfDownloadButtonContainer = styled.div`
  margin-left: 81px;
  margin-top: 28px;

  @media screen and (max-width: 1080px) {
    margin: 0;
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: fit-content;
  }
`;

export const AuditComplianceInfoWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1080px) {
    flex-direction: column-reverse;
  }
`;

export const AuditComplianceInfoDataWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 53.89%;

  @media screen and (max-width: 1280px) {
    max-width: 50%;
  }

  @media screen and (max-width: 1080px) {
    max-width: 60%;
  }

  @media screen and (max-width: 768px) {
    max-width: 300px;
  }
`;

export const TextDataAndIconWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

export const DataIconWrapper = styled(Box)`
  margin-right: 20px;
  display: block;

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

export const DataIconWrapperMobile = styled(Box)`
  display: none;

  @media screen and (max-width: 1080px) {
    display: block;
    transform: scale(0.65) translateY(2px);
  }
`;

export const TextDataWrapper = styled(Box)`
  @media screen and (max-width: 1080px) {
    text-align: center;
    margin-top: 20px;
  }
`;

export const TextDataTileAndIconWrapper = styled(Box)`
  @media screen and (max-width: 1080px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const TextDataTitle = styled(Typography)`
  font-weight: 600;
  font-size: 32px;
  line-height: 41.66px;
  color: #000a14;
  height: 42px;
`;

export const TextDataContent = styled(Typography)`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #000a14d9;
  margin-top: 2px;

  @media screen and (max-width: 1080px) {
    margin-top: 6px;
  }
`;

export const AuditReportSummaryCard = styled(Box)`
  border: 2px solid #e4e7eb;
  padding: 20px 24px;
`;

export const IssuesCategoriesCountWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const IssuesCategoryCountWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 30%;
  height: 36px;
  margin-bottom: 16px;

  @media screen and (max-width: 650px) {
    width: 45%;
  }

  @media screen and (max-width: 395px) {
    width: 100%;
  }
`;

export const IssuesCategoryCountView = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 51px;
  height: 36px;
  padding: 7px 16px;
  border-radius: 4px;
  border: 1px solid #e4e7eb;
`;

export const IssuesCategoryCount = styled(Typography)`
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.83px;
  text-align: left;
`;

export const IssuesCategory = styled(Typography)`
  color: #000a14d9;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.83px;
  margin-left: 12px;
`;
