import { ReportData } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import { ReactComponent as CheckIcon } from '../../assets/check-green-bg.svg';
import { ReactComponent as FacebookIcon } from '../../assets/fb-black-bg.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/linkedin-black-bg.svg';
import { ReactComponent as TwitterIcon } from '../../assets/twitter-black-bg.svg';
import {
  AuditReportSummaryCard,
  IssuesCategoriesCountWrapper,
  IssuesCategory,
  IssuesCategoryCount,
  IssuesCategoryCountView,
  IssuesCategoryCountWrapper,
} from './styles';
import { getIssuesCategoriesCount } from '@equally-ai-front/common/src/lib';
import { capitalizeFirstLetter } from '@equally-ai-front/common';
import { getSocialMediaShareElements } from '../../utils';

interface LowRiskAccessibilitySummaryProps {
  report: ReportData;
}

export const LowRiskAccessibilitySummary = (
  props: LowRiskAccessibilitySummaryProps,
) => {
  const { report } = props;
  const issuesCategoriesCount = getIssuesCategoriesCount(
    report.reportGroups.all,
  );

  const socialMediaShareElements = getSocialMediaShareElements(
    report.websiteUrl,
    <FacebookIcon />,
    <LinkedInIcon />,
    <TwitterIcon />,
  );

  return (
    <Wrapper>
      <SocialMediaShareWrapper>
        <CheckIconWrapper>
          <CheckIcon />
        </CheckIconWrapper>
        <SocialsAndText>
          <Title variant="h3">Great job! Share your badge with the world</Title>
          <SocialMediaWrapper>
            {socialMediaShareElements.map(({ link, icon }, smidx) => (
              <SocialMediaIconWrapper
                key={`${link}-${smidx}`}
                href={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {icon}
              </SocialMediaIconWrapper>
            ))}
          </SocialMediaWrapper>
        </SocialsAndText>
      </SocialMediaShareWrapper>
      <IssuesByCategoryWrapper>
        <IssuesCategoryTitle>Resolved by category</IssuesCategoryTitle>
        <IssuesCategoryWrapper>
          <IssuesCategoriesCountWrapper>
            {issuesCategoriesCount
              .filter(
                ([category]) =>
                  !['tables', 'orientation', 'media'].includes(category),
              )
              .map(([category, count]) => (
                <IssuesCategoryCountWrapper>
                  <IssuesCategoryCountView>
                    <IssuesCategoryCount variant="body1">
                      {count}
                    </IssuesCategoryCount>
                  </IssuesCategoryCountView>
                  <IssuesCategory variant="body1">
                    {capitalizeFirstLetter(category)}
                  </IssuesCategory>
                </IssuesCategoryCountWrapper>
              ))}
          </IssuesCategoriesCountWrapper>
        </IssuesCategoryWrapper>
      </IssuesByCategoryWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  width: 100%;
  margin-top: 22px;
`;

const SocialMediaShareWrapper = styled(AuditReportSummaryCard)`
  height: 118px;
  display: flex;

  @media screen and (max-width: 690px) {
    height: 143px;
  }
`;

const CheckIconWrapper = styled(Box)``;

const SocialsAndText = styled(Box)`
  margin-left: 16px;
`;

const Title = styled(Typography)`
  height: 36px;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: #000a14d9;

  @media screen and (max-width: 690px) {
    height: 48px;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }
`;

const SocialMediaWrapper = styled(Box)`
  display: flex;
  align-items: center;
  height: 30px;
  margin-top: 12px;
  gap: 12px;
`;

const SocialMediaIconWrapper = styled.a`
  cursor: pointer;
`;

const IssuesByCategoryWrapper = styled(AuditReportSummaryCard)`
  height: fit-content;
  margin-top: 30px;
`;

const IssuesCategoryWrapper = styled(Box)`
  margin-top: 20px;
`;

const IssuesCategoryTitle = styled(Title)`
  font-weight: 700;
`;
