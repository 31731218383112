import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  StyledTabs,
  StyledTab,
} from '@equally-ai-front/common/src/components/styled-tabs/styled-tabs';
import { MultiFilterSelect } from '@equally-ai-front/common/src/components/filters/multi-filter-select';
import { Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import styled from 'styled-components';
import { TextField } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import { ItemFilter } from '@equally-ai-front/common/src/components/filters/types';
import { Close } from '@mui/icons-material';
import { formatDate } from './domains-table-body';
import { StyledButton } from 'containers/coupons-page/coupons-table';
import * as ReactCSV from 'react-csv';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { useAdminAuth } from 'contexts/admin-context';
import { domainsCSVHeaders } from './consts';

const formatDateToReadableFormat = (value: number | string): string => {
  if (!value) return '';
  if (typeof value === 'number') {
    return moment(value).format('MMM Do, YYYY');
  }
  return moment(value, 'YYYY-MM-DD HH:mm:ss.SSSSSSZZ').format('MMM Do, YYYY');
};

interface DomainsPageTableHeaderProps {
  currentTab: number;
  onTabChange: (evt: React.SyntheticEvent, newValue: number) => void;
  filteredOptions: ItemFilter[];
  searchTerm: string;
  filters: Record<string, ItemFilter>;
  removeFilterType: (key: string) => void;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  handleSearchInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  addFilter: (filter: ItemFilter) => void;
  handleOptionClick: (filterKey: string | undefined) => void;
  handleFilterValueChange: (key: string, value: string) => void;
  handleFilterSecondValueChange: (key: string, value2: string) => void;
  newFilter: ItemFilter | undefined;
  isFilterFulfilled: () => boolean;
  handleFilterOperatorChange: (
    operator: string,
    filterValue?: string | number,
    isSatisfied?: (val: any) => boolean,
    isPreset?: boolean,
  ) => void;
  filterValueOptions: any[];
  domainSearchValue: string;
  handleSearch: (query: string) => void;
  currentDomainsData: any[];
}

export const DomainsPageTableHeader = (props: DomainsPageTableHeaderProps) => {
  const {
    currentTab,
    onTabChange,
    filteredOptions,
    searchTerm,
    filters,
    removeFilterType,
    setSearchTerm,
    handleSearchInputChange,
    addFilter,
    handleOptionClick,
    handleFilterValueChange,
    handleFilterSecondValueChange,
    newFilter,
    isFilterFulfilled,
    handleFilterOperatorChange,
    filterValueOptions,
    domainSearchValue,
    handleSearch,
    currentDomainsData,
  } = props;
  const { user } = useAdminAuth();
  const csvDownloadLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const [domainCsvData, setDomainCsvData] = useState<any[]>([]);

  const handleExportDomainsAsCSV = () => {
    const filteredData = currentDomainsData.map((data) => ({
      ...data,
      expirationDate: formatDateToReadableFormat(data.expirationDate),
      lastPaymentDate: formatDateToReadableFormat(data.lastPaymentDate),
      nextCapture: formatDateToReadableFormat(data.nextCapture),
      registrationDate: formatDateToReadableFormat(data.registrationDate),
    }));
    setDomainCsvData(filteredData);
  };

  const isUserPermittedToExport = useMemo(() => {
    if (!user) return false;
    const permissionsMap = user?.permissions_map;
    for (const permissions in permissionsMap) {
      const permission = permissionsMap[permissions];
      if (permission['feature.export_button']) {
        return true;
      }
    }
    return false;
  }, [user]);

  useEffect(() => {
    if (!csvDownloadLinkRef.current || domainCsvData.length === 0) {
      return;
    }
    csvDownloadLinkRef.current.link.click();
  }, [domainCsvData, csvDownloadLinkRef]);

  return (
    <>
      <DomainTableHeader>
        <Box display="flex" gap="10px" alignItems="center">
          <MultiFilterSelect
            filterValueOptions={filterValueOptions}
            handleFilterValueChange={handleFilterValueChange}
            handleFilterSecondValueChange={handleFilterSecondValueChange}
            handleFilterOperatorChange={handleFilterOperatorChange}
            searchTerm={searchTerm}
            addFilter={addFilter}
            isFilterFulfilled={isFilterFulfilled}
            setSearchTerm={setSearchTerm}
            handleOptionClick={handleOptionClick}
            handleSearchInputChange={handleSearchInputChange}
            options={filteredOptions}
            currentFilter={newFilter}
          />
          {isUserPermittedToExport && (
            <Box>
              <ExportButton onClick={handleExportDomainsAsCSV}>
                Export
              </ExportButton>
              <ReactCSV.CSVLink
                ref={csvDownloadLinkRef}
                data={domainCsvData}
                headers={domainsCSVHeaders}
                filename={`EQUALLY_AI_${domainCsvData?.[0]?.domainName}-issues-export.csv`}
                hidden
              >
                Download CSV
              </ReactCSV.CSVLink>
            </Box>
          )}
        </Box>
        <Box display="flex" alignItems="center" gap="35px">
          <Box display="flex" justifyContent="flex-end">
            <StyledTabs
              value={currentTab}
              onChange={onTabChange}
              orientation="horizontal"
              centered
            >
              <StyledTab
                label="Domains"
                style={{ textTransform: 'capitalize' }}
                value={0}
              />
              <StyledTab
                label="Business"
                style={{ textTransform: 'capitalize' }}
                value={1}
              />
            </StyledTabs>
          </Box>
          <FormControl>
            <DomainSearchBar
              type="text"
              value={domainSearchValue}
              onChange={(evt) => handleSearch(evt.target.value)}
              id="search-input"
              placeholder="Search"
              variant="outlined"
            />
          </FormControl>
        </Box>
      </DomainTableHeader>
      {Object.values(filters).length > 0 && (
        <SelectedFilterWrapper>
          {Object.values(filters).map(
            ({
              key,
              displayName,
              filterType,
              filterCondition,
              value,
              value2,
            }) => (
              <SelectedFilterTag key={key}>
                <FilterTag>
                  <TagLabel>{displayName}:</TagLabel>
                  <TagValue>
                    {filterType === 'date' ? formatDate(value) : value}
                  </TagValue>
                  {value2 && (
                    <TagValue style={{ marginLeft: '4px' }}>
                      &nbsp;and&nbsp;
                      {filterType === 'date'
                        ? formatDate(Number(value2))
                        : value2}
                    </TagValue>
                  )}
                </FilterTag>
                <FilterTag>
                  <TagLabel>Operator:</TagLabel>
                  <TagValue>
                    {filterCondition && filterCondition.replaceAll('_', ' ')}
                  </TagValue>
                </FilterTag>
                <RemoveTag onClick={() => removeFilterType(key)} />
              </SelectedFilterTag>
            ),
          )}
        </SelectedFilterWrapper>
      )}
    </>
  );
};

const DomainTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  padding-top: 20px;
`;

const DomainSearchBar = MuiStyled(TextField)({
  '.MuiOutlinedInput-input': {
    height: 10,
  },
});

const SelectedFilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 2px solid rgb(228, 231, 235);
  border-radius: 8px;
  padding: 10px;
  margin: 20px 0;
`;

const SelectedFilterTag = styled.p`
  border-radius: 16px;
  background-color: rgb(245, 247, 250);
  position: relative;
  margin: 10px 10px 10px 0;
  padding: 7px 28px 7px 12px;
  font-size: 12px;
`;

const FilterTag = styled.span`
  margin: 0;
  display: block;
`;

const TagLabel = styled.span`
  font-weight: 500;
  margin-right: 4px;
`;

const TagValue = styled.span``;

const RemoveTag = styled(Close)`
  color: red;
  position: absolute;
  font-size: 15px !important;
  top: 2px;
  right: 10px;
  cursor: pointer;
`;

const ExportButton = styled(StyledButton)`
  margin-right: 5px;
  height: 43px;
`;
