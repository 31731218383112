import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ComplianceService } from '../api';
import AuditAPI from '../api/audit';
import { Issue, NewScanInfo, Scan } from '../types/issues';
import { getUrlWithoutHttpPrefixAndWWW } from '../utils/helpers';
import { useAnalytics } from '../contexts/AnalyticsProvider';
import { ScanStatus } from '../lib/scans';

enum IssueTypes {
  error = 1,
  warning = 2,
  notice = 3,
}

const getIssueTypeByValue = (value: string): number => {
  return (
    {
      error: IssueTypes.error,
      warning: IssueTypes.warning,
      notice: IssueTypes.notice,
    }[value] || 0
  );
};
const convertFromRawData = (url: string, result: any[]) => {
  // const severityToData: SeverityToData = {
  //     error: [],
  //     warning: [],
  //     notice: []
  // };
  const issues: Issue[] = [];

  let i = 0;
  for (const issue of result) {
    issues.push({
      business_id: 0,
      code: issue.code,
      context: issue.context || '',
      created_at: new Date().toString(),
      domain_url: url || window.location.href,
      id: i,
      message: issue.message,
      recommendation: '{}',
      scan_id: 0,
      selector: issue.selector,
      source: 'Automated',
      status: 'Pending',
      type: issue.type,
      type_code: getIssueTypeByValue(issue.type),
      updated_at: '',
      user_id: 0,
      website_id: 0,
      component: null,
    });
    i++;
  }

  // return {
  //   created_at: new Date(),
  //   error: severityToData.error.length,
  //   warning: severityToData.warning.length,
  //   notice: severityToData.notice.length,
  //   raw_data: JSON.stringify(result),
  //   pally_data: severityToData,
  //   url: window.location.href
  // };
  return issues;
};

interface AutomaticScanProps {
  onError: (message: string) => void;
  isAdmin?: boolean;
}

interface PendingScan {
  failures: number;
  scannedAt: Date;
  requestId: string;
}

interface ResolvedScanResponse {
  scan: Scan;
  isSuccess: boolean;
}

export const useAutomaticScan = (props: AutomaticScanProps) => {
  const { onError, isAdmin } = props;
  const [isLoading, setIsLoading] = useState(false);
  const analytics = useAnalytics();
  // const [isWidgetAppeared, setIsWidgetAppeared] = useState(false);
  // const [scanImageUrl, setScanImageUrl] = useState('');
  const failuresRef = useRef(0);
  const scanIdToFailsMapRef = useRef<Record<string, PendingScan>>({});
  const isResolveRunning = useRef(false);
  const [activeScansMap, setActiveScansMap] = useState<Record<string, Scan>>(
    {},
  );
  const [completedScansMap, setCompletedActiveScansMap] = useState<
    Record<string, Scan>
  >({});
  const isAdminRef = useRef(isAdmin);

  useEffect(() => {
    isAdminRef.current = isAdmin;
  }, [isAdmin]);

  useEffect(() => {
    if (Object.keys(activeScansMap).length === 0) {
      return;
    }
    const requestIds = Object.keys(activeScansMap);
    for (const requestId of requestIds) {
      if (!scanIdToFailsMapRef.current[requestId]) {
        scanIdToFailsMapRef.current[requestId] = {
          failures: 0,
          scannedAt: new Date(),
          requestId,
        };
      }
    }
    // sample request to handle single scan(websiteId=0) for now
    const scan = activeScansMap[requestIds[0]];
    const {
      website_id: websiteId,
      business_id: businessId,
      domain: urlToScan,
    } = scan;

    const runResolve = async () => {
      if (!isResolveRunning.current) {
        isResolveRunning.current = true;
        await resolveAuditAPI(urlToScan, businessId, websiteId);
      }
    };
    void runResolve();
  }, [activeScansMap, scanIdToFailsMapRef]);

  const resolveAudit = async (
    businessId: number,
    websiteId: number,
    urlToScan: string,
    requestIds: string[],
  ): Promise<ResolvedScanResponse[]> => {
    let data: any;
    let isSuccess: boolean;

    if (isAdminRef.current) {
      const { data: responseData, isSuccess: isResponseSuccess } =
        await AuditAPI.resolveComplianceAdmin({
          requestIds,
          urlToScan: `https://${getUrlWithoutHttpPrefixAndWWW(urlToScan)}`,
        });
      data = responseData;
      isSuccess = isResponseSuccess;
    } else {
      const { data: responseData, isSuccess: isResponseSuccess } =
        await AuditAPI.resolveCompliance({
          businessId,
          requestIds,
          urlToScan: `https://${getUrlWithoutHttpPrefixAndWWW(urlToScan)}`,
          websiteId: websiteId === 0 ? undefined : websiteId,
        });
      data = responseData;
      isSuccess = isResponseSuccess;
    }

    if (websiteId !== 0) {
      return data.map((scan: Scan) => {
        scan.scan_image_url = websiteId === 0 ? data.scan_image_url : '';
        scan.is_widget_appeared =
          websiteId === 0 ? data.is_widget_appeared : false;
        return {
          scan: scan,
          isSuccess: isSuccess,
        };
      });
    } else {
      const scan = data.scan as Scan;
      scan.scan_image_url = websiteId === 0 ? data.scan_image_url : '';
      scan.is_widget_appeared =
        websiteId === 0 ? data.is_widget_appeared : false;
      return [
        {
          scan: data.scan,
          isSuccess: isSuccess,
        },
      ];
    }
  };

  const getIssues = async (scan: Scan): Promise<Issue[]> => {
    const {
      id: scanId,
      website_id: websiteId,
      business_id: businessId,
      domain: urlToScan,
      raw_data: rawData,
    } = scan;
    let issues;
    if (websiteId !== 0) {
      const { data, isSuccess } = await ComplianceService.export(
        websiteId,
        businessId,
        urlToScan,
        scanId,
      );
      if (!isSuccess) {
        onError('Could not perform audit. Please try again.');
        return [];
      }
      issues = data as Issue[];
    }
    failuresRef.current = 0;
    if (websiteId === 0) {
      issues = convertFromRawData(urlToScan, JSON.parse(rawData));
    }
    return issues || [];
  };

  const getScansSummary = (
    resolvedScans: ResolvedScanResponse[],
  ): {
    activeScansMap: Record<string, Scan>;
    completedScansMap: Record<string, Scan>;
  } => {
    const newActiveScansMap = { ...activeScansMap };
    const newCompletedScansMap = { ...completedScansMap };

    const resolvedScansMap = resolvedScans.reduce(
      (acc, resolvedScan) => {
        acc[resolvedScan.scan.request_id] = resolvedScan;
        return acc;
      },
      {} as Record<string, ResolvedScanResponse>,
    );
    for (const requestId of Object.keys(scanIdToFailsMapRef.current)) {
      const { scan: currentScan, isSuccess } = resolvedScansMap[requestId] || {
        isSuccess: false,
      };
      const scan = currentScan || newActiveScansMap[requestId];

      if (!isSuccess) {
        if (scanIdToFailsMapRef.current[scan.request_id].failures < 10) {
          scanIdToFailsMapRef.current[scan.request_id].failures += 1;
        } else {
          delete scanIdToFailsMapRef.current[scan.request_id];
          newCompletedScansMap[scan.request_id] = scan;
        }
      } else if (
        scan.status === ScanStatus.pending ||
        scan.status === ScanStatus.processed
      ) {
        if (scanIdToFailsMapRef.current[scan.request_id].failures >= 10) {
          delete scanIdToFailsMapRef.current[scan.request_id];
          delete newActiveScansMap[scan.request_id];

          scan.status = ScanStatus.failed;
          newCompletedScansMap[scan.request_id] = scan;
        }

        if (scanIdToFailsMapRef.current[scan.request_id]) {
          scanIdToFailsMapRef.current[scan.request_id].failures += 1;
          newActiveScansMap[scan.request_id] = scan;
        }
      } else if (scan.status === ScanStatus.success) {
        delete scanIdToFailsMapRef.current[scan.request_id];
        delete newActiveScansMap[scan.request_id];
        newCompletedScansMap[scan.request_id] = scan;
      } else if (scan.status === ScanStatus.failed) {
        delete scanIdToFailsMapRef.current[scan.request_id];
        delete newActiveScansMap[scan.request_id];
        newCompletedScansMap[scan.request_id] = scan;
      }
    }
    return {
      activeScansMap: newActiveScansMap,
      completedScansMap: newCompletedScansMap,
    };
  };
  const resolveAuditAPI = async (
    urlToScan: string,
    businessId: number,
    websiteId?: number,
  ): Promise<{ data: ResolvedScanResponse[]; isSuccess: boolean }> => {
    try {
      let currentRequestIds: string[] = [];
      Object.values(scanIdToFailsMapRef.current).forEach((scanFailures) => {
        // if (scanFailures.scannedAt < new Date(new Date().getTime() - 60000)) {
        //   delete scanIdToFailsMapRef.current[scanFailures.requestId];
        // } else {
        currentRequestIds.push(scanFailures.requestId);
        // }
      });
      // const activeScansRequestIds = Object.keys(activeScansMap);
      const resolvedScanResponses = await resolveAudit(
        businessId,
        websiteId || 0,
        urlToScan,
        currentRequestIds,
      );
      const {
        completedScansMap: newCompletedScansMap,
        activeScansMap: newActiveScansMap,
      } = getScansSummary(resolvedScanResponses);
      setActiveScansMap(newActiveScansMap);
      setCompletedActiveScansMap(newCompletedScansMap);

      currentRequestIds = [];
      Object.values(scanIdToFailsMapRef.current).forEach((scanFailures) => {
        // if (scanFailures.scannedAt < new Date(new Date().getTime() - 60000)) {
        //   delete scanIdToFailsMapRef.current[scanFailures.requestId];
        // } else {
        currentRequestIds.push(scanFailures.requestId);
        // }
      });

      if (currentRequestIds.length > 0) {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        await resolveAuditAPI(urlToScan, businessId, websiteId);
      } else {
        isResolveRunning.current = false;
        setIsLoading(false);
      }
      return { data: resolvedScanResponses, isSuccess: true };
    } catch (error) {
      isResolveRunning.current = false;
      onError('Could not perform audit. Please try again.');
      return { data: [], isSuccess: false };
    }
  };

  const performAudit = useCallback(
    async (
      urlToScan: string,
      businessId: number,
      websiteId?: number,
      scanDetails?: NewScanInfo,
    ): Promise<{ scans?: Scan[]; scanGroup: any; isSuccess: boolean }> => {
      const urls = urlToScan
        .split(';')
        .map((url) => decodeURI(url.trim()))
        .filter((url) => url.length > 0);

      const checkAsyncCompliance = async (urls: string[]) => {
        try {
          const { data, isSuccess } = await AuditAPI.checkAsyncGroupCompliance({
            // urls: `https://${getUrlWithoutHttpPrefixAndWWW(url)}`,
            urls,
            businessId,
            websiteId: websiteId === 0 ? undefined : websiteId,
            scanName: scanDetails?.scan_name,
            target: scanDetails?.target,
          });

          if (!isSuccess) {
            onError('Could not perform audit. Please try again.');
            setIsLoading(false);
            return { isSuccess: false };
          }
          const { scans, scan_group: scanGroup } = data;
          return {
            isSuccess: true,
            // @ts-ignore
            scans: scans,
            scanGroup: scanGroup,
          };
        } catch (error) {
          onError(
            'An error occurred while performing audit. Please try again.',
          );
          setIsLoading(false);

          return { isSuccess: false };
        }
      };
      // const responses = [];

      // for (const url of urls) {
      // responses.push(checkAsyncCompliance(urls));
      // }
      setIsLoading(true);
      // const results = await Promise.all(responses);
      const {
        scans: newScans,
        scanGroup,
        isSuccess,
      } = await checkAsyncCompliance(urls);
      if (!isSuccess) {
        setIsLoading(false);
        return { scans: [], scanGroup, isSuccess: false };
      }
      // const newScans = results.filter((result) => result.isSuccess);
      const newActiveScansMap = { ...activeScansMap };

      for (const scan of newScans) {
        newActiveScansMap[scan.scan.request_id] = scan.scan;
      }
      setActiveScansMap({
        ...activeScansMap,
        ...newActiveScansMap,
      });
      return { scans: newScans, scanGroup, isSuccess: true };
    },
    [activeScansMap],
  );

  const getLatestScan = useCallback(
    async (
      urlToScan: string,
      businessId: number,
      websiteId: number,
    ): Promise<{ issues: Issue[]; scan: Scan }> => {
      setIsLoading(true);
      const { data, isSuccess } = await ComplianceService.getLatestScan(
        businessId,
        websiteId,
        urlToScan,
      );

      if (!isSuccess) {
        onError('Could not perform audit. Please try again.');
        setIsLoading(false);
        return { issues: [], scan: {} as Scan };
      }

      return {
        issues: data?.issues || [],
        scan: (data?.scan || {}) as Scan,
      };
    },
    [],
  );
  const scans = useMemo(() => {
    return [
      ...Object.values(activeScansMap),
      ...Object.values(completedScansMap),
    ];
  }, [completedScansMap, activeScansMap]);

  const removeCompletedScans = (requestIds: string[]) => {
    const newCompletedScansMap = { ...completedScansMap };
    requestIds.forEach((requestId) => {
      delete newCompletedScansMap[requestId];
    });
    setCompletedActiveScansMap(newCompletedScansMap);
  };

  const performSingleAudit = useCallback(
    async (
      urlToScan: string,
      businessId: number,
      websiteId?: number,
    ): Promise<{ scan?: Scan; isSuccess: boolean }[]> => {
      const urls = urlToScan
        .split(';')
        .map((url) => decodeURI(url.trim()))
        .filter((url) => url.length > 0);

      const checkAsyncCompliance = async (url: string) => {
        try {
          let data;
          let isSuccess;
          let errorMsg;
          if (isAdminRef.current) {
            const {
              data: responseData,
              isSuccess: isResponseSuccess,
              error,
            } = await AuditAPI.checkComplianceAdmin(
              `https://${getUrlWithoutHttpPrefixAndWWW(url)}`,
            );
            data = responseData;
            isSuccess = isResponseSuccess;
            errorMsg = error;
          } else {
            const {
              data: responseData,
              isSuccess: isResponseSuccess,
              error,
            } = await AuditAPI.checkAsyncCompliance({
              urlToScan: `https://${getUrlWithoutHttpPrefixAndWWW(url)}`,
              businessId,
              websiteId: websiteId === 0 ? undefined : websiteId,
            });
            data = responseData;
            isSuccess = isResponseSuccess;
            errorMsg = error;
          }

          if (!isSuccess) {
            const error = errorMsg?.includes('no such host')
              ? 'Invalid url'
              : 'Could not perform audit. Please try again.';
            onError(error);
            setIsLoading(false);
            return { isSuccess: false };
          }

          if (!isAdminRef.current) {
            const trackPayload: Record<string, any> = {
              url: getUrlWithoutHttpPrefixAndWWW(url),
              is_widget: data?.is_widget_appeared,
              request_id: data?.scan?.request_id,
            };
            if (businessId) {
              trackPayload['business_id'] = businessId;
            }
            analytics.track('Accessibility Report Requested', trackPayload);

            if (businessId) {
              // @ts-ignore
              analytics.group(businessId, {
                last_audit_report_at: new Date().toISOString(),
                last_audit_report_url: getUrlWithoutHttpPrefixAndWWW(url),
                used_audit_report: true,
              });
            }
          }

          const scan = isAdminRef.current ? data.data.scan : data.scan;
          return { isSuccess: true, scan };
        } catch (error) {
          onError(
            'An error occurred while performing audit. Please try again.',
          );
          setIsLoading(false);

          return { isSuccess: false };
        }
      };
      const responses = [];

      for (const url of urls) {
        responses.push(checkAsyncCompliance(url));
      }
      setIsLoading(true);
      const results = await Promise.all(responses);

      const newScans = results.filter((result) => result.isSuccess);
      const newActiveScansMap = { ...activeScansMap };

      for (const scan of newScans) {
        newActiveScansMap[scan.scan.request_id] = scan.scan;
      }
      setActiveScansMap({
        ...activeScansMap,
        ...newActiveScansMap,
      });
      return results;
    },
    [activeScansMap],
  );

  return {
    performAudit,
    isLoading,
    getLatestScan,
    getIssues,
    scans,
    performSingleAudit,
    removeCompletedScans,
  };
};
