import { Scan } from '../types/issues';

const DOMAIN_REGEX =
  /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i;
export const isDomainNameValid = (domainName: string) =>
  DOMAIN_REGEX.test(domainName);

export const removeSlashAtUrlEnd = (url: string): string => {
  const lastCharacter = url.charAt(url.length - 1);
  const formattedUrl =
    lastCharacter === '/' && url.length > 1
      ? url.substring(0, url.length - 1)
      : url;
  return formattedUrl;
};

export const removeLeadingSlash = (str: string): string => {
  return str.replace(/^\//, '');
};

export const getPageName = (currentScan: Scan): string => {
  if (!currentScan) return '';
  const { url, domain } = currentScan;
  const urlWithoutTrailingSlash = (url || domain).endsWith('/')
    ? url.slice(0, -1)
    : url;
  let pageName = '';
  const urlPathList = urlWithoutTrailingSlash.split('/');
  if (
    urlPathList.length > 1 ||
    ['header', 'footer'].includes(urlWithoutTrailingSlash)
  ) {
    pageName = `${urlPathList[urlPathList.length - 1].replace(
      /[-_]/g,
      ' ',
    )} Page`;
  } else {
    pageName = 'Main Page';
  }
  return pageName;
};

export const isDatePastNumberOfDays = (date: string, days: number): boolean => {
  const givenDate = new Date(date);
  if (isNaN(givenDate.getTime())) {
    throw new Error('Invalid date format');
  }

  const now = new Date();
  const thresholdDate = new Date(now);
  thresholdDate.setDate(now.getDate() - days);
  return givenDate < thresholdDate;
};
